import {
    DocumentData,
    FirestoreDataConverter,
    collection,
    deleteDoc,
    doc,
    getDoc,
    onSnapshot,
    setDoc,
} from 'firebase/firestore'

import { firestore } from './firebase.ts'
import { FSDocument } from './types.ts'

export interface GetDocumentParams<T> {
    collectionName: string
    id?: string
    onDocumentChange?: (doc: T) => void
    converter?: FirestoreDataConverter<any, any>
}

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }

const mapNullToUndefined = <T extends DocumentData>(data: T) =>
    Object.keys(data).reduce((acc, key) => ({ ...acc, [key]: data[key] === null ? undefined : data[key] }), {})

export const listenDocument = async <T extends FSDocument>({
    collectionName,
    id,
    onDocumentChange,
    converter,
}: WithRequired<GetDocumentParams<T>, 'id'>): Promise<T | undefined> => {
    const docRef = converter
        ? doc(firestore, collectionName, id).withConverter(converter)
        : doc(firestore, collectionName, id)

    const snapshot = await getDoc(docRef)
    if (!snapshot.exists()) return undefined

    if (onDocumentChange) {
        onSnapshot(docRef, docSnapshot => {
            if (docSnapshot.exists()) {
                onDocumentChange({ ...mapNullToUndefined(docSnapshot.data()), id: docSnapshot.id } as T)
            }
        })
    }

    return { ...mapNullToUndefined(snapshot.data()), id: snapshot.id } as T
}

export interface SetDocumentParams<T> {
    collectionName: string
    id?: string
    data: T
    converter?: FirestoreDataConverter<any, any>
}

const mapUndefinedToNull = <T extends DocumentData>(data: T) =>
    Object.keys(data).reduce((acc, key) => ({ ...acc, [key]: data[key] === undefined ? null : data[key] }), {})

export const setDocument = async <T extends DocumentData>({
    collectionName,
    id,
    data,
    converter,
}: SetDocumentParams<T>): Promise<T & { id: string }> => {
    let docRef: ReturnType<typeof doc>

    if (id) {
        // either the doc exists or we want to create it with a specific id
        docRef = converter
            ? doc(firestore, collectionName, id).withConverter(converter)
            : doc(firestore, collectionName, id)
        await setDoc(docRef, mapUndefinedToNull(data), { merge: true })
    } else {
        // the doc does not exist, an id will be autogenerated
        const collectionRef = converter
            ? collection(firestore, collectionName).withConverter(converter)
            : collection(firestore, collectionName)
        docRef = doc(collectionRef)
        await setDoc(docRef, mapUndefinedToNull(data), { merge: true })
    }

    const snapshot = await getDoc(docRef)
    if (!snapshot.exists()) {
        throw new Error('Document was not created successfully')
    }
    return { ...mapNullToUndefined(snapshot.data()), id: snapshot.id } as T & { id: string }
}

export interface DeleteDocumentParams {
    collectionName: string
    id: string
    converter?: FirestoreDataConverter<any, any>
}

export const deleteDocument = async ({ collectionName, id, converter }: DeleteDocumentParams): Promise<void> => {
    const docRef = converter
        ? doc(firestore, collectionName, id).withConverter(converter)
        : doc(firestore, collectionName, id)
    await deleteDoc(docRef)
}
