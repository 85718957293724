import { useEffect } from 'react'

import { useCollection } from '@/firebase/hooks/useCollection'
import { Order, OrderConverter } from '@tipi/shared'

import { MOCK_ORDERS } from './mock'

// Helper function to sanitize order data for Firestore
const sanitizeOrderForFirestore = (order: any): Order => {
    // Create a new object with all properties, replacing undefined with appropriate defaults
    return {
        ...order,
        status: order.status || 'unconfirmed',
        products: order.products || [],
        deliveryTime: order.deliveryTime || null,
        contactPhone: order.contactPhone || null,
        chunkId: order.chunkId || null,
    }
}

export const useOrders = () => {
    const {
        results: orders,
        isLoading,
        addDocument,
        updateDocument,
        removeDocument,
    } = useCollection<Order>({
        path: 'organizations/demo/orders',
        converter: OrderConverter,
    })

    useEffect(() => {
        const initializeOrders = async () => {
            console.log('isLoading', isLoading)
            if (!isLoading && orders.length === 0) {
                console.log('No orders found, initializing with mock data')

                for (const order of MOCK_ORDERS) {
                    console.log('order', order)
                    // Sanitize order data to prevent Firestore errors from undefined values
                    const sanitizedOrder = sanitizeOrderForFirestore(order)
                    await addDocument(sanitizedOrder)
                }
            }
        }

        initializeOrders()
    }, [isLoading, orders.length, addDocument])

    const getAllOrders = () => orders

    console.log('orders', orders)

    return {
        orders,
        isLoading,
        updateDocument,
        removeDocument,
        getAllOrders,
    }
}
