import { Recipe, recipeConverter } from '@tipi/shared'

import { useCollection } from '../../../firebase/hooks/useCollection'

export const useRecipes = () => {
    const {
        results: recipes,
        isLoading,
        addDocument,
        updateDocument,
        removeDocument,
    } = useCollection<Recipe>({
        path: 'organizations/demo/recipes',
        limit: 1000,
        converter: recipeConverter,
    })
    console.log('recipes', recipes)

    const getAllRecipes = () => recipes

    const getRecipeById = (id: string): Recipe | undefined => recipes.find(recipe => recipe.id === id)

    const getRecipesByCategory = (category: string): Recipe[] => recipes.filter(recipe => recipe.category === category)

    const getMenuRecipes = (): Recipe[] => recipes.filter(recipe => recipe.inMenu)

    const toggleRecipeMenuStatus = (id: string) => {
        const recipe = recipes.find(r => r.id === id)
        if (recipe) {
            updateDocument(id, { inMenu: !recipe.inMenu })
        }
    }

    return {
        getAllRecipes,
        getRecipeById,
        getRecipesByCategory,
        getMenuRecipes,
        toggleRecipeMenuStatus,
        isLoading,
        addRecipe: addDocument,
        updateRecipe: updateDocument,
        removeRecipe: removeDocument,
    }
}
